









































import {
  Component, Vue, Prop, Watch, Ref,
} from 'vue-property-decorator';
import { Library, LibraryUpdateModel } from '@/models/library-maintenance.d';
import * as validation from '@/models/validation';
import MetadataEditor from '@/components/library-maintenance/metadata-editor.vue';
import EcTextField from 'common-components/src/components/form/ec-text-field.vue';
import HdQueryEditor from '@/components/form/hd-query-editor.vue';
import { FieldList, Field, SelectListItem } from '@/models/form';
import { ApiError } from '../../models/hal.d';

@Component({
  components: {
    MetadataEditor,
    EcTextField,
    HdQueryEditor,
  },
})
export default class LibraryEditor extends Vue {
  @Prop({ default: () => ({}) }) value!: Library;

  @Prop({ default: () => ([]) }) metadataKeys!: SelectListItem[]

  @Prop({ default: () => ([]) }) apiErrors!: ApiError[];

  get editorOptions() {
    return {
      validate: validation.queryExpression,
      language: 'hdql',
    };
  }

  fieldList = new FieldList({
    name: new Field(
      '',
      'Name',
      [
        validation.required(),
        validation.maxLength(50),
      ],
      [],
    ),
    description: new Field(
      '',
      'Description',
      [
        validation.maxLength(200),
      ],
      [],
    ),
    notes: new Field(
      '',
      'Internal Notes',
      [],
      [],
      'internal-notes',
    ),
    filter: new Field(
      '',
      'Filter',
      [],
      [],
    ),
    metadata: new Field(
      {},
      'Metadata',
    ),
  });

  get fields() { return this.fieldList.fields; }

  @Watch('value', { immediate: true })
  onValueChanged() {
    this.reset();
  }

  @Watch('apiErrors')
  onErrorsChanged() {
    this.fieldList.addAllErrors(this.apiErrors);
  }

  reset() {
    const clone = JSON.parse(JSON.stringify(this.value));

    this.fields.name.value = clone.name;
    this.fields.description.value = clone.description;
    this.fields.notes.value = clone['internal-notes'];
    this.fields.filter.value = clone.filter;
    this.fields.metadata.value = clone.metadata;
  }

  @Ref() form!: HTMLFormElement

  @Ref() queryEditor!: HdQueryEditor

  fetch(
    callback: (library: LibraryUpdateModel) => void,
    validationCallback: () => void,
  ) {
    const valid = this.form.validate()
      && validation.queryExpression(this.fields.filter.value, '')
      && !this.queryEditor.checkZeroWidthSpace();

    if (!valid) {
      validationCallback();
      return;
    }

    const updatedLibrary: LibraryUpdateModel = {
      name: this.fields.name.value,
      description: this.fields.description.value,
      'internal-notes': this.fields.notes.value,
      filter: this.fields.filter.value,
      metadata: this.fields.metadata.value,
    };

    callback(updatedLibrary);
  }

  checkZeroWidthSpace(): boolean {
    return this.queryEditor.checkZeroWidthSpace();
  }
}
