
























































































































import {
  Component, Inject, Vue, Watch, Ref,
} from 'vue-property-decorator';

import LibraryService from '@/services/library-service';
import LibraryEditor from '@/components/library-maintenance/library-editor.vue';
import Card from '@/components/material/Card.vue';
import { SnackbarOptions } from '@/models/form';
import EcSnackBar from 'common-components/src/components/form/ec-snack-bar.vue';
import { Library, LibraryUpdateModel } from '@/models/library-maintenance.d';
import { backOr } from '@/router';

import { getModule } from 'vuex-module-decorators';
import AppState from '@/store/modules/app-module';
import { ApiError } from '../models/hal.d';

const appState = getModule(AppState);

@Component({
  components: {
    LibraryEditor,
    Card,
    EcSnackBar,
  },
})
export default class LibraryView extends Vue {
  @Inject() LibraryService!: LibraryService

  libraryId?: string;

  library: Library = {} as Library;

  metadataKeys = [
    { header: 'Select a predfined metadata key or create a custom one' },
    { text: 'category' },
    { text: 'sub-category' },
    { text: 'priority' },
  ];

  apiErrors: ApiError[] = [];

  snackbarOptions: SnackbarOptions = EcSnackBar.makeDefaultOptions();

  loading = false;
  creatingExport = false;

  get editing() { return Boolean(this.libraryId); }

  @Ref() editor!: LibraryEditor

  @Watch('$route', { immediate: true })
  onRouteChanged(value: any): Promise<void> {
    this.libraryId = value.params.id;

    if (!this.libraryId) return Promise.resolve();

    this.loading = true;
    return this.LibraryService
      .readSingle(this.libraryId)
      .then((library) => {
        this.library = library;
        this.loading = false;
      });
  }

  @Watch('library')
  onLibraryUpdated() {
    appState.setTitle(`Library: ${this.library?.name}`);
  }

  back() {
    backOr({ name: 'libraries' });
  }



  async exportLibrary(){
    //pop up something asking for the password of the import.
    this.creatingExport = true;
    try {
      const result = await this.LibraryService.getExport(this.libraryId);

      const anchor = document.createElement('a');
      const url = window.URL.createObjectURL(new Blob([result.fileContents]));
      anchor.href = url;
      anchor.setAttribute('download', result.fileName);
      anchor.setAttribute('target', '_blank');
      document.body.appendChild(anchor);
      anchor.click();
      anchor.remove();
    } finally {
      this.creatingExport = false;
    }
  }

  save(libraryUpdate: LibraryUpdateModel) {
    if (!this.libraryId) {
      this.snackbarOptions = EcSnackBar.makeProgressOptions('Creating library ...');
      this.LibraryService.createLibrary(libraryUpdate)
        .then((library) => {
          this.snackbarOptions.value = false;
          this.$router.replace({ name: 'library', params: { id: library.id } });
          EcSnackBar.makeSuccessfulOptions('Successfully created'); 
                  
        })
        .catch((http) => {
          this.snackbarOptions = EcSnackBar.makeDefaultOptions();
          this.apiErrors = http.response?.data?.errors || [];
        });
      return;
    }

    this.snackbarOptions = EcSnackBar.makeProgressOptions('Saving library ...');
    this.LibraryService.updateLibrary(this.libraryId, libraryUpdate)
      .then((library) => {
        this.snackbarOptions.value = false;
        this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeSuccessfulOptions('Successfully updated'); });

        this.library = library;
      })
      .catch((http) => {
        this.snackbarOptions = EcSnackBar.makeDefaultOptions();
        this.apiErrors = http.response?.data?.errors || [];
      });
  }

  formValidationError() {
    if (this.editor.checkZeroWidthSpace()) {
      this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions('Zero width spaces are not allowed');
    } else {
      this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions('Please correct any errors before saving');
    }
  }

  async setEnabledState(enable: boolean) {
    if (!this.libraryId) return;

    try {
      const text = enable
        ? 'If you enable this library, it is likely that all rules belonging to it will start to be processed and start generating hits.'
        : 'If you disable this library, any rule belonging to it will no longer be processed and will not generate hits.';

      appState.openDialog({
        title: enable ? 'Enable Library?' : 'Disable Library?',
        text: `<p>Are you sure?</p><p>${text}</p>`,
        actions: [{
          name: enable ? 'Enable' : 'Disable',
          color: 'warning',
          handler: async () => {
            this.snackbarOptions = EcSnackBar.makeProgressOptions('Changing library ...');
            this.LibraryService.setEnabledState(this.libraryId ?? "", enable)
              .then(() => {
                const local = this.library;
                local.enabled = enable;

                this.snackbarOptions.value = false;
                const message = `Successfully ${enable ? 'enabled' : 'disabled'} library`;
                this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeSuccessfulOptions(message); });
              })
              .catch((error: Error) => {
                this.snackbarOptions.value = false;
                const message = `Failed to ${enable ? 'enable' : 'disable'} library: ${error.message}`;
                this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(message); });
              })
            },
          }, {
            name: 'Cancel',
            color: 'primary',
            handler: () => Promise.resolve(false),
          }],
      });
    } catch (error) {
      this.snackbarOptions.value = false;
      this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions('Failed to change library'); });
    }
  }

  async deleteLibrary() {
    if (!this.libraryId) return;

    try {
      appState.openDialog({
        title: 'Delete Library?',
        text: '<p>Are you sure?</p><p>If you delete this library, any rule belonging to it will no longer be processed and will not generate hits.</p>',
        actions: [{
          name: 'Delete',
          color: 'warning',
          handler: async () => {
            this.snackbarOptions = EcSnackBar.makeProgressOptions('Deleting library ...');
            this.LibraryService.deleteLibrary(this.libraryId)
              .then(() => {
                  this.snackbarOptions.value = false;
                  this.back();
                }
              )
              .catch((error: Error) => {
                  this.snackbarOptions.value = false;
                  this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(`Failed to delete library: ${error.message}`); });
                }
              )
            }
          },
          {
            name: 'Cancel',
            color: 'primary',
            handler: () => Promise.resolve(false),
          }
        ]
      });
    } catch (error) {
      this.snackbarOptions.value = false;
      this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(`Failed to delete library: ${error}`); });
    }
  }
}
